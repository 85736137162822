import React from 'react';

const NotFoundPage = () => {
  return (
    <div>
      <h5>The page you have requested is Not Found!</h5>
    </div>
  );
}

export default NotFoundPage;